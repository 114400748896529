
import { Component, Prop, Vue } from "vue-property-decorator";
import VueQrious from "vue-qrious";
import { translations } from "@/mixins";


@Component({
  components: {
    VueQrious,
  },
  mixins: [translations],
})
export default class QRTFA extends Vue {
  @Prop() qr_uri!: string;
  
  translate!: Function;

  mounted() {
    //
  }
}
